<template>
  <div class="login mt-6 pt-6">
    <div class="container">
      <div class="columns is-justify-content-center">
        <div class="column is-two-fifths-tablet">
          <header class="has-text-centered">
            <img
                class="login-logo mb-4"
                src="/img/assets/logo.png"
                alt="Sgara Logo">

            <h3 class="is-size-4 has-text-weight-bold">Insert your New Password</h3>
          </header>

          <b-notification
              :active.sync="showError"
              :closable="false"
              class="my-4 has-text-centered"
              type="is-danger is-light"
              aria-close-label="Close notification"
              role="alert">
            {{ errorMessage }}
          </b-notification>

          <ValidationObserver ref="form">
            <form @submit.prevent="login" class="mt-4">
              <ValidationProvider name="Password" rules="required" v-slot="{ errors }" vid="password">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input type="password" id="password" v-model="user.password"
                           placeholder="Enter your Password"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Password Confirmation" rules="required|confirmed:password" v-slot="{ errors }">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input type="password" id="password_confirmation" v-model="user.password_confirmation"
                           placeholder="Repeat your Password"></b-input>
                </b-field>
              </ValidationProvider>

              <b-button type="is-primary is-gradient" expanded class="mb-3" native-type="submit">Reset Password</b-button>
              <b-button type="is-primary" expanded outlined to="/login" tag="router-link">Login</b-button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => {
    return {
      user: {},
      showError: false,
      errorMessage: '',
    }
  },
  methods: {
    async login() {
      this.$refs.form.validate().then(async success => {
        if (!success) return 0

        this.showError = false
        this.errorMessage = ''

        this.$loading()
        let res = await this.$store.dispatch('user/resetPassword', {
          signature: this.$route.query.signature,
          password: this.user.password,
          password_confirmation: this.user.password_confirmation,
        })
        this.$loading(false)
        if (!res.error) return this.$router.push('/')
        else {
          if (res.data.message) {
            this.showError = true
            this.errorMessage = res.data.message
          }
        }
      })
    }
  }
}
</script>
